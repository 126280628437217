import { createContext, useContext, useEffect, useState } from "react";

export const ReaderContext = createContext({});

export const useReader = () => useContext(ReaderContext);

export const ReaderProvider = ({ children }) => {
  const [tab, setTab] = useState("");

  const [inTextAnalysisTasks, setInTextAnalysisTasks] = useState([]);
  const [inTextAnalysisPrompt, setInTextAnalysisPrompt] = useState({
    active: false,
    text: "",
    prompt: "",
  });
  const [lastSelectedText, setLastSelectedText] = useState(null);
  const [isReaderMenuHidden, setIsReaderMenuHidden] = useState(null);
  const [analysisOpen, setAnalysisOpen] = useState(false);

  useEffect(() => {
    if (isReaderMenuHidden) {
      document.body.classList.add("reader-menu-hidden");
    } else {
      document.body.classList.remove("reader-menu-hidden");
    }
  }, [isReaderMenuHidden]);

  return (
    <ReaderContext.Provider
      value={{
        tab,
        setTab,
        inTextAnalysisTasks,
        setInTextAnalysisTasks,
        inTextAnalysisPrompt,
        setInTextAnalysisPrompt,
        setLastSelectedText,
        lastSelectedText,
        isReaderMenuHidden,
        setIsReaderMenuHidden,
        analysisOpen,
        setAnalysisOpen,
      }}
    >
      {children}
    </ReaderContext.Provider>
  );
};
